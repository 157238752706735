<template>
    <div class="container">
        <div class="page-box-layout login-content">
            <div class="left hidden-xs-only">
                <router-link to="/" class="title">秀动经营平台</router-link>
                <p>如需要帮助，请联系电话：400-188-6980 周一至周五 10:00~18:30</p>
                <p>©{{new Date().getFullYear()}}成都太合乐动科技有限公司 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备14016442号-4</a></p>
            </div>
            <div class="right">
                <div class="login-box">
                    <div class="title">重置密码</div>                    
                    <el-form :model="formData" :rules="rules" ref="formData" hide-required-asterisk >                       
                            <el-form-item prop="phone">
                                <div class="flexBox">
									<el-input v-model="formData.phone" placeholder="账号手机号" style="width: 80%;"></el-input>
									<span class="findUser" @click="findUser()">识别账号</span>
								</div>
								<div v-if="userShow"><span v-if="userResult">账号名称:{{userResult.name}}</span><span v-else class="color-danger">该手机号没有对应的B类账号</span></div>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input v-model="formData.password" type="password" placeholder="新密码" show-password  auto-complete="new-password"></el-input>
                            </el-form-item>
							<el-form-item prop="password2">
							    <el-input v-model="formData.password2" type="password" placeholder="再次输入新密码" show-password  auto-complete="new-password"></el-input>
							</el-form-item>
							<el-form-item prop="securityCode">
							    <div class="input-with-button">
							        <el-input v-model="formData.securityCode" placeholder="请输入短信验证码" ></el-input>
							        <el-button
							            native-type="button"
							            type="text"
							            @click="getCode()"
							            :disabled="codeBtn.disabled"
							            :loading="codeBtn.loading"
							        >{{codeBtn.btnText}}</el-button>
							    </div>
							</el-form-item>                        
                        <el-form-item>
                            <el-button type="primary" class="btn submit-button" @click="submitForm()" :loading="loginLoading">提 交</el-button>							
                        </el-form-item>
                    </el-form>
                    
                </div>
            </div>
        </div>
        
       

    </div>
</template>

<script>
var md5 = require('js-md5');
import {verificationSwitch} from '@/assets/js/commonApi';
export default {
   
    data(){
		const validatePassword = (rule, value, callback) => {
			 const _reg = /^(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9 _]{8,40}$/
		     if (!_reg.test(value)) {
		         callback(new Error("密码必须包含8-40个字符，且必须包含至少1位小写字母、1位数字"));
		     } else {
		         callback();
		     }
		 }
		 //验证确认密码
		 const validateNewPwd2 = (rule, value, callback) => {
		     if (value !== this.formData.password) {
		         callback(new Error("两次输入密码不一致!"));
		     } else {
		         callback();
		     }
		 }
        return{
            formData:{
                phone: '',
                password: '',
				password2: '',
				securityCode:''
            },
            loginType:1,
            rules: {
                phone: [{ required: true, message: "请填写账号手机号" }],                
                securityCode:[{ required: true, message: "请输入短信验证码" }],
				password:[
				    {required:true,message:'请输入密码'},
					{validator:validatePassword}
				],
				password2:[
				    {required:true,message:'请再次确认新密码'},
					{validator:validateNewPwd2}
				],	
            },
            codeBtn: {
                btnText: "获取验证码",
                disabled: false,
                loading: false
            },
            loginLoading: false,
            captcha: null,
            redirect: this.$route.query.redirect || '/manage/home',
            masterUsers:[],
			userShow:false,
			userResult:{}
        }
		
    },
    created() {
        const that = this;
        this.$utils.loadScript(
            {
                id:'tcaptcha',
                url:'https://turing.captcha.qcloud.com/TCaptcha.js'
            },
            () =>{
                // eslint-disable-next-line no-undef
                this.captcha = new TencentCaptcha(
                    "2032912416", 
                    function(res){
                        if(res.ret === 0){
                            that.getCodeEvent( res );
                        }
                    }
                );
            }
        )
    },
    methods:{
        
		//标别用户
		findUser(){
			if( this.formData.phone == ''){
				return this.$message.error("手机号不能为空！");
			}
			this.$request({
			    url: "/user/login/getUserInfoByPhone",
			    method: "POST",
			    params: {
			        phone: this.formData.phone,
			        
			    },
			})
			    .then((res) => {
			        const { state, msg ,result } = res.data;
			        if (state == 1 ) {
						this.userShow = true
			            this.userResult = result
			        }
			    })
			    .catch(() => {
			        this.$message.error('请求错误');
			    })
		},
        //获取短信验证码
        getCode() {
            this.$refs["formData"].validateField(["phone"],async (err) => {
                if(!err){
                    const res = await  verificationSwitch(); //获取验证码设置
                    const { state, msg, result } = res.data;
                    if (state != 1) {
                        this.$message.error(msg || "获取验证码设置失败");
                    } else {
                        if( result.suduResetPassword ){
                            this.captcha.show();
                        }else{
                            this.getCodeEvent();
                        }
                    }
                }
            });
        },
        getCodeEvent(_res){
            this.codeBtn.loading = true;
            this.$request({
                url: "/user/login/securityCodeResetPassword",
                method: "POST",
                params: {
                    phone: this.formData.phone,
                    randStr:_res && encodeURIComponent(_res.randstr) || '',
                    ticket: _res && encodeURIComponent(_res.ticket) || '',
                },
            })
                .then((res) => {
                    const { state, msg } = res.data;
                    if (state != 1) {
                        this.$message.error(msg || "请求失败");
                    } else {
                        // this.formData.securityCode = res.data.result;
                        this.dtime(60)
                    }
                })
                .catch(() => {
                    this.$message.error('请求错误');
                }).finally( () => {
                    this.codeBtn.loading = false;
                });
        },
        dtime( time ){
            var that = this , _time = time;
            if( _time <= 0 ){
                this.codeBtn.btnText = '获取验证码';
                this.codeBtn.disabled = false;
            }else{
                this.codeBtn.btnText =  _time+'秒后重试' ;
                _time --;
                this.codeBtn.disabled = true;
                let t1 = setTimeout(function(){ that.dtime( _time );clearTimeout(t1); } , 1000);
            }
        },
	
        //提交表单
        submitForm() {
            let self = this;
            self.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.loginLoading = true;
                    const _url = '/user/login/resetPassword' 
                    this.$request({
                        url: _url,
                        method: "POST",
                        params: {password:md5(this.formData.password).toLowerCase(),phone:this.formData.phone,code:this.formData.securityCode}
                    }).then( res => {
                        const { state, msg, result } = res.data;
                        if (state == 1 && msg == 'SUCCESS') {
                            this.$message.success("密码重置成功");
							this.$router.push({path:'/login'})
                        } else {
							this.$message.error(msg || "请求失败");
                        }  
                    }).catch(() => {
                        this.$message.error('请求错误');
                    }).finally( () => {
                        this.loginLoading = false;
                    });
                } else {
                    return false;
                }
            });
        },
       
    }
}
</script>

<style lang="scss" scoped>
.container{
    background: url('~@/assets/img/login/bg.png') center center no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    min-height: 100vh;
    @media screen and (max-width: $--screen-sm) {
        background: #000;
    }
	
    .login-content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
            color: #ffffff;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
            .title{
                font-size: 56px;
                margin-bottom: 260px;
                color: #ffffff;
                display: block;
            }
            p{
                font-size: 13px;
                line-height: 22px;
            }
            a{
                color: #ffffff;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .right{
            background: #ffffff;
            border-radius: 4px;
            padding: 50px 80px 100px 80px;
            @media screen and (max-width: $--screen-md) {
                padding: 50px 40px;
            }
            .login-box{
                width: 356px;
                @media screen and (max-width: $--screen-md) {
                    width: 280px;
                }
				.flexBox{
					display: flex;
					justify-content: space-between;
				}
				.findUser{
					cursor: pointer;
					color: #145AF2;
				}
                .title{
                    font-size: 36px;
                    font-weight: 600;
                }
                .tips{
                    font-size: 13px;
                    color: $--color-info;
                    margin-top: 12px;
                    a{
                        color: $--color-theme;
                    }
                }
                .el-form{
                    margin-top: 60px;
                    .input-with-button{
                        position: relative;
                        .el-button{
                            position: absolute;
                            right: 0;
                            top: 0;
                            padding: 12px 20px;
                        }
                    }
                    // .input-with-select{
                    //     ::v-deep .el-input__inner{
                    //         // border-right: 0;
                    //     }
                    //     ::v-deep .el-input-group__append{
                    //         background: none;
                    //     }
                    // }
                }
                .btn{
                    width: 100%;
                    &.submit-button{
                        margin-top: 26px;
                    }
                    &.default-button{
                        ::v-deep span{
                            font-weight: 600;
                        }
                    }
                }
                .or{
                    position: relative;
                    text-align: center;
                    margin: 30px 0;
                    span{
                        padding: 0 20px;
                        background: #ffffff;
                        position: relative;
                        z-index: 1;
                    }
                    &:after{
                        content: "";
                        position: absolute;
                        height: 1px;
                        background: $--border-color-base;
                        width: 100%;
                        left: 0;
                        top: 50%;
                    }
                }
            }
        }
    }
}
</style>